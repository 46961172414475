.photo_roller{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.roller{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 500px;
    margin-bottom: 5%;
    margin-top: 2%;
    background-color: aliceblue;
    box-shadow: 0px 0px 15px 8px rgba(46, 46, 46, 0.377);
}

.fade-in {
    opacity: 0;
    transform: translateY(150px);
    transition: opacity 0.5s, transform 1s;
}

.show {
    opacity: 1;
    transform: translateY(0);
}  

.display{
    width: 50%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0px 0px 10px 5px rgba(68, 61, 61, 0.26);
    transition: 1s;
}

.display:hover{
    box-shadow: 0px 0px 10px 15px rgba(68, 61, 61, 0.26);
    transition: 1s;
}

.photo_desc{
    width: 50%;
    height: 60%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.desc_text{
    display: flex;
    width: 70%;
    min-height: 60%;
    align-items: center;
    justify-content: center;
    font-size: 1.75em;
    font-weight: 600;
    text-align: center;
}

.button{
    display: flex;
    width: 40%;
    height: 20%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 78, 224, 0.363);
    border-radius: 20px;
    border: none;
    text-decoration: none;
    color: inherit;
    font-weight: 700;
    font-size: 2.5em;
    transition: 0.5s;
    cursor: pointer;
}

.button:hover{
    transition: 0.5s;
    background-color: rgba(0, 78, 224, 0.808);
    width: 50%;
}

.right{
    justify-content: flex-end;
    border-left-width: 3px;
    border-left-style: solid;
    border-color: orange;
}

.left{
    justify-content: flex-start;
    border-right-width: 3px;
    border-right-style: solid;
    border-color: orange;
}

@media screen and (max-width: 1000px){
    .roller{
        width: 100%;
        margin-top: 0;
        height: 300px;
        margin-bottom: 100px;
        background-color: aliceblue;
        box-shadow: 0px 0px 10px 10px rgba(46, 46, 46, 0.377);
    }

    .photo_desc{
        background-color: transparent;
    }

    .desc_text{
        width: 80%;
        height: 80%;
        font-size: 1.25em;
    }

    .button{
        font-size: 1.25em;
        width: 70%;
        background-color: rgba(0, 78, 224, 0.808)
    }

    .button:hover{
        width: 70%;
        background-color: rgba(0, 78, 224, 0.363);
    }
}