.body_title{
    display: flex;
    width: 100%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    font-weight: 900;
    text-align: center;
    margin-top: 80px;
    padding-top: 50px;
    padding-bottom: 40px;
    text-decoration: underline;
    text-decoration-color: orange;
}

.story_display{
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.regular{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
}

.story_box_top, .story_box_right, .story_box_left{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: 800px;
    color: white;
}

.text_sb_bottom, .text_sb_left, .text_sb_right{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    font-size: 30px;
}

.img_sb_top, .img_sb_right, .img_sb_left{
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    background: transparent;
}

/*TOP*/

.text_sb_bottom{
    width: 100%;
    height: 50%;
    text-align: center;
    bottom: 0;
}

.img_sb_top{
    object-fit: cover;
    width: 100%;
    height: 50%;
    top: 0;
}

/*---------*/
/*RIGHT*/

.text_sb_left{
    left: 0;
    width: 50%;
    height: 100%;
    text-align: left;
    margin-left: 0;
}

.img_sb_right{
    object-fit: contain;
    right: 0;
    width: 50%;
    height: 100%;
}

/*---------*/
/*LEFT*/

.text_sb_right{
    right: 0;
    width: 50%;
    height: 100%;
    text-align: right;
    margin-left: 0;
}

.img_sb_left{
    object-fit: cover;
    left: 0;
    width: 50%;
    height: 100%;
}

/*---------*/

.center{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    height: auto;
}
.margin{
    text-align: inherit;
    font-size: inherit;
    color: rgb(104, 104, 104);
}
.bolder{
    margin-right: 7px;
    color: rgb(0, 0, 0);
}

.img_story{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 70%;
    object-fit: inherit;
}

@media screen and (max-width: 1000px){

    .body_title{
        font-size: 40px;
    }

    .story_display{
        margin-top: 0px;
    }

    .story_box_top, .story_box_right, .story_box_left{
        position: relative;
        flex-wrap: wrap;
        width: 100%;
        height: 600px;
        margin-bottom: 100px;
    }

    .text_sb_bottom, .text_sb_left, .text_sb_right{
        position: relative;
        width: 100%;
        height: 50%;
        text-align: center;
        font-size: 20px;
    }

    .img_sb_top, .img_sb_right, .img_sb_left{
        position: relative;
        width: 100%;
        height: 50%;
        right: auto;
        left: auto;
    }

    .text_story_box{
        font-size: 15px;
        height: 50%;
    }

    .center{
        width: 90%;
        height: 80%;
    }

    .img_story{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}