.title{
    margin-top: 80px;
    display: flex;
    font-size: 3em;
    font-weight: 800;
    align-items: center;
    width: 100%;
    height: 120px;
    padding-left: 10%;
}

@media screen and (max-width: 1000px){
    .title{
        font-size: 2.5em;
    }
}