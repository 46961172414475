.spare_parts_body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.intro_spare_parts{
    display: flex;
    margin-top: 80px;
    justify-content: center;
    align-items: center;
    height: 600px;
    width: 100%;
}

.key_intro_spare_parts{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-image: linear-gradient(orange, white, white);
}

.singolar_intro{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.border_left{
    border-left: 5px solid white;
}

.parts_intro_img{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 100%;
    object-fit: contain;
}

.parts_intro_title{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
    text-align: center;
    font-size: 50px;
    text-transform: capitalize;
    font-weight: 800;
}

.parts_button_container{
    display: none;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    width: 100%;
    height: 30%;
}

.stock_body{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 600px;
}

.stock_images{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 1000px){
    .intro_spare_parts{
        height: 300px;
    }
    .parts_intro_img{
        width: 70%;
    }
    
    .parts_intro_title{
        font-size: 30px;
    }
}