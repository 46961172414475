.not_found_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
    margin-top: 80px;
    color: orange;
    font-size: 25px;
    font-weight: bold;
}

.not_found_icon{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 90%;
}

.not_found_img {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: rotate 20s linear infinite; /* Adjust duration and timing function as needed */
    width: 200px;
    height: 200px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.not_found_sentence{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 10%;
}