.head_container{
    width: 100%;
    height: 80px;
    background-color: rgb(0, 79, 224);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5em;
    font-weight: bold;
    position: fixed;
}

.logo_box{
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.menu{
    display: flex;
    width: 60%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.menu_box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu_box_link{
    text-decoration: none;
    color: inherit;
    text-transform: capitalize;
}

.date_found{
    font-size: 13px;
}

.logo_box_link{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    color: white;
    text-decoration: none;
}

.logo{
    width: auto;
    height: 70px;
}

.sidebar_icon{
    display: none;
}

.sidebar{
    display: none;
}

.invisible{
    display: none;
}

@media screen and (max-width: 1000px){
    .menu{
        width: 90%;
    }

    .menu_box_link{
        font-size: 0.55em;
    }

    .utility_box_link{
        font-size: 0.65em;
    }

    .logo_name{
        font-size: 20px;
    }

    .date_found{
        font-size: 10px;
    }

    .logo_box_link{
        margin-left: 50px;
    }
}

@media screen and (max-width: 500px){
    .logo_box{
        width: 100%;
    }

    .menu{
        width: 0%;
        display: none;
    }

    .sidebar_icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20%;
        height: 100%;
    }

    .m_icon{
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        height: auto;
        cursor: pointer;
    }

    .sidebar{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        right: 0;
        top: 80px;
        position: absolute;
        width: 40%;
        height: 350px;
        background-color: rgb(0, 66, 189);
    }

    .mid_sidebar_box{
       display: flex;
       flex-wrap: wrap;
       width: 100%;
       height: 100%;
       align-items: center;
       justify-content: center;
       text-align: center;
    }

    .menu_box{
        height: 10%;
        font-size: 35px;
        margin: 0;
        justify-content: center;
    }
}
