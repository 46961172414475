.page_body{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: max-content;
    background-color: white;
    position: absolute;
    z-index: -3;
}

.headliner{
    position: absolute;
    z-index: -1;
    width: 100%;
}

.photo_display{
    margin-top: 600px;
}

@media screen and (max-width: 500px){
    .photo_display{
        margin-top: 400px;
    }
}