#intro_box{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
}

.txt_img_laser_exposition{
    display: flex;
    width: 100%;
    height: fit-content;
    align-items: center;
    justify-content: center;
}

.txt_top, .txt_bottom{
    display: flex;
    flex-wrap: wrap;
}

#intro_img_box{
    display: flex;
    width: 100%;
    height: 500px;
    align-items: center;
    justify-content: center;
}

.intro_img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

#intro_text{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 500px;
    align-items: center;
    justify-content: center;
}

#textdivs_box{
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    height: 60%;
}

#subtitle_spanbox, #title_spanbox{
    display: flex;
    width: 100%;
    height: 20%;
    text-align: center;
    align-items: center;
    justify-content: center;
}

#subtitle_spanbox{
    height: 80%;
}

.span_title{
    display: flex;
    width: 100%;
    height: 100%;
    text-transform: none;
    font-size: 50px;
    font-weight: 750;
    justify-content: center;
    align-items: center;  
}

.span_subtitle{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    font-size: 35px;
    font-weight: bolder;
    color: rgb(114, 114, 114);
    text-align: center;
}

.over{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1000px;
}

#over_img_box{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient( rgb(0, 0, 0), rgb(116, 116, 116), white);
}

#over_text {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1000px){
    .span_title{
        font-size: 30px;
    }

    .span_subtitle{
        font-size: 20px;
    }

    #intro_img_box{
        height: 200px;
    }

    #intro_text{
        height: 400px;
    }

    .over{
        height: 500px;
    }
}

@media screen and (min-width: 1000px) and (max-width: 1500px){
    .span_title{
        font-size: 35px;
    }

    .span_subtitle{
        font-size: 25px;
    }

    #intro_img_box{
        height: 400px;
    }

    #intro_text{
        height: 400px;
        width: 150%;
    }

    .over{
        height: 500px;
    }
}