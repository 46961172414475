.box{
    width: 100%;
    height: 800px;
    margin-top: 80px;
    display: flex;
    align-items: center;
    justify-content: right;
    background-image: url(../images/wallpaper.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.intro{
    display: flex;
    align-items: top;
    justify-content: center;
    text-align: center;
    color: black;
    background-color: transparent;
    width: 60%;
    height: 90%;
    font-size: 4em;
    font-weight: 900;
    margin-right: 10%;
    margin-top: 15%;
}

@media screen and (max-width: 1000px) {
    .box{
        justify-content: center;
    }
    .intro{
        font-size: 50px;
        margin: 0;
        width: 60%;
    }
    
}

@media screen and (max-width: 500px){
    .intro{
        font-size: 40px;
        margin: 0;
        width: 90%;
    }
}