.footer_container{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    background-color: rgb(20, 46, 117);
    color: white;
}

.copyright{
    width: 100%;
    text-align: center;
    font-size: 10px;
}

.info_container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 250px;
    background-color: rgb(20, 46, 117);
    color: white;
}

.footer_element{
    display: flex;
    width: 20%;
    height: 80%;
    align-items: center;
    justify-content: center;
    border-top: 2px solid orange;
}


.homepage_link_box{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.homepage_link{
    display: flex;
    width: auto;
    height: 25%;
}

.link_to{
    height: 100%;
}

.foot_reference{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.foot_reference_list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.foot{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10%;
}

.media{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 20%;
}

.google_maps{
    width: 10px;
    height: 10px;
}

@media screen and (max-width: 1000px) {
    .footer_element{
        display: flex;
        width: 30%;
    }

    .foot_reference{
        font-size: 15px;
        text-align: center;
    }
    
}