.contact_box{
    display: flex;
}

.reference{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    width: 50%;
    height: 500px;
}

.reference_list{
    width: 60%;
    height: 80%;
    border: 2px solid rgb(0, 79, 224);
    border-radius: 5px;
    /*border-image: linear-gradient(to top left, orange, blue, orange) 1;*/
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.ref{
    display: flex;
    width: 60%;
    font-weight: 500;
}

.company_name{
    font-weight: 800;
}

.cuntry{
    text-transform: capitalize;
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-image: linear-gradient(to right, green, white, white, red)1 ;
    width: 50px;
    height: auto;
}

.city{
    text-transform: capitalize;
}

.address{
    text-transform: capitalize;
}

@media screen and (max-width: 1000px){
    .contact_box{
        display: flex;
        flex-wrap: wrap;
    }
    
    .reference{
        width: 100%;
        height: 500px;
    }

    .reference_list{
        width: 90%;
    }

    .ref{
        width: 80%;
    }
}