.send_container{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 50%;
    height: 800px;
}

.sendEmail_box{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    height: 50%;
    align-items: center;
    justify-content: center;
}

.label{
    display: flex;
    width: 100%;
    color: black;
    text-transform: capitalize;
    font-size: 1.25em;
    justify-content: center;
    align-items: center;
}

.input{
    width: 60%;
    height: 50px;
    border-radius: 10px;
    border: 2px solid orange;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 500;
}

.text_area{
    display: flex;
    min-width: 60%;
    max-width: 60%;
    min-height: 80%;
    max-height: 80%;
    resize: none;
    border-radius: 10px;
    font-size: 15px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 500;
}

.send_button{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.send{
    display: flex;
    width: 300px;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
    border: none;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}

.active{
    background-color: rgb(0, 79, 224);;
    color: white;
}

.inactive{
    background-color: rgba(0, 0, 255, 0.274);
    color: rgba(255, 255, 255, 0.61);
}

.sent{
    transition: 0.5s;
    background-color: rgb(0, 187, 0);
}

.error{
    color: firebrick;
}

@media screen and (max-width: 1000px){
    .send_container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 800px;
    }

    .sendEmail_box{
        width: 100%;
        height: fit-content;
        margin-bottom: 50px;
    }

    .input{
        width: 70%;
    }

    .text_area{
        min-width: 70%;
        max-width: 70%;
        min-height: 150px;
        max-height: 150px;
        resize: none;
    }

    .send{
        margin-top: 5%;
        width: 40%;
    }

}