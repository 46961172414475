.above_margin{
    margin-top: 80px;
}

.home_product_box{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: transparent;
    overflow-x: hidden;
}

.tractor_product{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: transparent;
    padding-top: 3%;
}

.laser_product{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: black;
    color: white;
    padding-top: 3%;
}

.sb_product_title{
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: orange;
}

.subject_box{
    width: 100%;
    height: 900px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-top: 5%;
    background-image: linear-gradient(transparent, transparent, transparent, rgba(255, 166, 0, 0.466));
}

.sbj{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
    text-transform: capitalize;
}

.upper_box{
    width: 100%;
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.sbtitle{
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    text-align: center;
}

.box_sb_image{
    width: 50%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sb_image{
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: contain;
}

.tractor_sb_button{
    width: 30%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    display: none;
}

.laser_sb_button{
    width: 30%;
    height: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.sb_second_title{
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    text-align: center;
}

.sb_images_slider{
    width: 100%;
    height: 30%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
}

.machines_sb_photo_box{
    display: flex;
    flex-wrap: wrap;
    width: 12%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-x: hidden;
}

.laser_sb_photo_box{
    display: flex;
    flex-wrap: wrap;
    width: 12%;
    height: fit-content;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-x: hidden;
}

.sb_brand{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 210px;
    height: 145px;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 20px;
    background-color: rgba(192, 192, 192, 0.171);
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    overflow-x: hidden;
}

.sb_fade-in_prod {
    opacity: 0;
    transform: translateX(200px);
    transition: opacity 0.5s, transform 1s;
    position: relative;
    overflow-x: hidden;
}

.show_prod {
    opacity: 1;
    transform: translateX(0);
} 

@media screen and (max-width: 1000px){
    .sb_product_title, .sbj{
        width: 100%;
        height: 100px;
        font-size: 32px;
    }

    .subject_box{
        height: 1000px;
        background-image: linear-gradient(transparent, transparent, transparent, rgba(255, 166, 0, 0.466));
    }

    .sbtitle{
        width: 80%;
        font-size: 20px;
    }

    .box_sb_image{
        width: 100%;
        height: 60%;
        justify-content: center;
    }

    .sb_image{
        width: 80%;
    }

    .tractor_sb_button, .laser_sb_button{
        width: 60%;
        height: 40%;
        align-items: flex-start;
    }

    .sb_second_title{
        width: 80%;
        font-size: 20px;
    }

    .machines_sb_photo_box{
        width: 29%;
    }

    .laser_sb_photo_box{
        width: 30%;
    }

    .sb_brand{
        width: 110px;
        height: 80px;
    }
}